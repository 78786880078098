import React from "react";
import {Helmet} from "react-helmet";

import Layout from '../components/Layout'
import CareersOpenPosition from '../components/Careers/CareersOpenPosition'

import Favicon from "../images/Favicon.png";

const CareersOpenPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Careers - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="description"
                      content="Join Drivosity and be part of a global team that enhances food delivery with safety and productivity. Apply now to work with industry leaders and make a difference in last-mile delivery. Explore career opportunities today."/>
            </Helmet>
            <Layout component={<CareersOpenPosition/>}/>
        </>
    )
}
export default CareersOpenPage

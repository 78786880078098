import styled from "styled-components";

export const OpenPositionContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: #808080;
`;

export const OpenPositionDescription = styled.div`
    margin-bottom: 20px;
`;
import React, { useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { request } from '../../../services/api';

import { Container, ApplyInput,UploadFile, UploadFileText, SubmitButton, ErrorMessage, ApplyTextArea } from './DrvApplyForm.styles';
export const DrvApplyForm = (props) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [emailSend, setEmailSend] = useState(false);
    const [reqError, setReqError] = useState(false);
    const [reqErrorMsg, setReqErrorMsg] = useState('');
    const [inputs, setInputs] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        location: "",
        linkedin: "",
        cv: "",
        cl: {},
        website: ""
    })
    const defaultErrors = {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        location: null,
        cv: null
    };
    const [errors, setErrors] = useState(defaultErrors);
    const validateNumber = (evt) => {
        var theEvent = evt || window?.event;
        var key = theEvent.clipboardData.getData("text/plain");
        setErrors({
            ...errors,
            [evt.target.name] : false
        })
        // Handle paste
        if (theEvent.type === "paste") {
            key = theEvent.clipboardData.getData("text/plain");
        } else {
            // Handle key press
            key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    };
    const inputChange = (e) =>{
        const {name, value} = e.target;
        setInputs({
            ...inputs,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: false
        })
    };  
    const validateField = (name, value) =>{
        switch (name){
            case "firstName":
                if(!value){
                    return true;
                }
                break;
            case "lastName":
                if(!value){
                    return true;
                }
                break;
            case "phone":
                if(!value){
                    return true;
                }
                break;
            case "location":
                if(!value){
                    return true;
                }
                break;
            case "cv":
                if(!value){
                    return true;
                }
                break;
            case "email":
                if(!value){
                    return true;
                }
                else if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)){
                    return true;
                }
                break;
            default:
                break;
        }
    }
    const Submit = async (e) => {
        e.preventDefault();
        if(!executeRecaptcha){
            return
        }else{
            
        }
        const result = await executeRecaptcha();
        let errorObj = {};
        setErrors(defaultErrors);
        let errorHandler = false;
        Object.entries(inputs).forEach(([item,val]) =>{
            if(typeof validateField(item, val)){
                if(validateField(item, val)){
                    errorObj = {...errorObj, [item]: validateField(item, val)};
                    errorHandler = true;
                }
            }
        })
        setErrors(errorObj);
        if(!errorHandler){
            let formData = new FormData();
            formData.append('captcha_token',result);
            formData.append('cv_file',inputs.cv);
            formData.append('cover_file',inputs.cl);
            formData.append('job_title',props.jobTitle);
            formData.append('first_name',inputs.firstName);
            formData.append('last_name',inputs.lastName);
            formData.append('email',inputs.email);
            formData.append('phone_number',inputs.phone);
            formData.append('location',inputs.location);
            formData.append('linkedin',inputs.linkedin);
            formData.append('website',inputs.website);
            try {
                const response = await request('post','/email/job', formData);
                if(response.success){
                    setEmailSend(true);
                }else{
                    setReqError(true);
                    setReqErrorMsg('Something went wrong, Please try again later')
                }
            } catch (err) {
                console.error(err)
                setReqError(true)
                if(typeof err.message === 'string') {
                    setReqErrorMsg(err.message)
                } else {
                    setReqErrorMsg('Something went wrong, Please try again later')
                }
            }
        }
    };
    return (
        <>
            <Container className="w-full lg:w-10/12 p-[45px] md:p-[60px]">
                {
                    !emailSend && (
                        <>
                            <div className="drv-title" style={{fontSize: "35px"}}>
                                APPLY FORM
                            </div>
                            <ApplyInput type="text" className={errors.firstName ? "error" : ""} onChange={inputChange} value={inputs.firstName} name="firstName" placeholder="First Name *" />
                            <ApplyInput type="text" className={errors.lastName ? "error" : ""} onChange={inputChange} value={inputs.lastName} name="lastName" placeholder="Last Name*" />
                            <ApplyInput type="email" className={errors.email ? "error" : ""} onChange={inputChange} value={inputs.email} name="email" placeholder="Email*" />
                            <ApplyInput type="tel" className={errors.phone ? "error" : ""} onChange={inputChange} value={inputs.phone} onKeyPress={validateNumber} name="phone" placeholder="Phone Number *" />
                            <ApplyInput type="text" className={errors.location ? "error" : ""} onChange={inputChange} value={inputs.location} name="location" placeholder="Location *" />
                            <UploadFile className="flex flex-wrap">
                                <UploadFileText className="flex basis-full md:basis-1/2">
                                    Upload your CV *
                                </UploadFileText>
                                <ApplyInput className={"flex basis-full md:basis-1/2 " + (errors.cv ? "error" : "") + ""} name="cv" 
                                onChange={(e) => {
                                    setInputs({
                                        ...inputs,
                                        cv : e.target.files[0]
                                    })
                                    setErrors({
                                        ...errors,
                                        cv: false
                                    })
                                }}
                                type="file" placeholder="No file Choosen"/>
                            </UploadFile>
                            <UploadFile className="flex flex-wrap">
                                <UploadFileText className="flex basis-full md:basis-1/2" >
                                    Upload your Cover Letter 
                                </UploadFileText>
                                <ApplyInput className={"flex basis-full md:basis-1/2 "} name="cl" 
                                onChange={(e) => {
                                    setInputs({
                                        ...inputs,
                                        cl: e.target.files[0]
                                    })
                                }}
                                type="file" placeholder="No file Choosen" />
                            </UploadFile>
                            <ApplyInput type="text" onChange={inputChange} value={inputs.linkedin} name="linkedin" placeholder="LinkedIN" />
                            <ApplyTextArea placeholder="Website" cols="40" rows="10" name="website" onChange={inputChange}>
            
                            </ApplyTextArea>
                            <SubmitButton 
                                onClick={Submit}>
                                SUBMIT
                                <AiOutlineArrowRight style={{fontSize: "15px"}} className="ml-2" />
                            </SubmitButton>
                                {
                                    (errors.firstName || errors.lastName || errors.email || errors.location) && (
                                        <ErrorMessage>
                                            One or more fields have an error. Please check and try again.
                                        </ErrorMessage>
                                    )
                                }
                                 {
                                    (reqError) && (
                                        <ErrorMessage>
                                            {reqErrorMsg}
                                        </ErrorMessage>
                                    )
                                }
                        </>
                    )
                }
                {
                    emailSend && (
                        <div className="drv-title">
                            Email Sent Successfully
                        </div>
                    )
                }
            </Container>
        </>
    )
}

import React, {useMemo, useRef} from 'react'
import DrvCover from '../Shared/DrvCover/DrvCover'
import CareersImage from "../../images/Careers/Cover.jpeg";
import { DrvLinkPath } from '../Shared/DrvLink/DrvLinkPath';
import { useQueryParam, NumberParam } from "use-query-params";

import { OpenPositionDescription } from './CareersOpenPosition.styles';

import { JobsData } from './Careers.data'
import DrvContent from '../Shared/DrvContent/DrvContent';
import { DrvApplyForm } from '../Shared/DrvApplyForm/DrvApplyForm';

const CareersOpenPosition = () => {

    const [index, setIndex] = useQueryParam('aW5kZXg', NumberParam);
    const currIndex = useRef(0);
    useMemo(() => {
        if(typeof index == 'number' && index < JobsData.length && index > 0){
            currIndex.current = index
        }else{
            setIndex(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    let data = JobsData[currIndex.current];
    const handleClick = (e) => {
        e.preventDefault();
        const target = e.target.getAttribute('href');
        const location = document.querySelector(target).offsetTop;
        window?.scrollTo({
            left: 0,
            top: location - 100,
            behavior: 'smooth'
        })
    }
    return (
        <>
            <DrvCover
                title={`APPLY FOR ${data?.title.toUpperCase()}`}
                color='#ffffff'
                image={CareersImage}
            />
            <DrvContent
                type={['text']}
                content={[
                    (
                        <>
                            <DrvLinkPath items={[{ text: 'Drivosity', link: '/', isLink: true }, { text: 'Careers', link: '/careers', isLink: true }, { text: `APPLY FOR ${data?.title.toUpperCase()}`, link: '#', isLink: false }]} />
                            <div className="drv-title mt-8">
                                {data?.title.toUpperCase()}
                            </div>
                            <div className="flex flex-row mb-5">
                                <div>
                                    {data?.data.location}, {data?.data.employmentType} |
                                </div>
                                <div>
                                    <a className="link" form="forma" onClick={handleClick} href="#forma">&nbsp; APPLY FORM</a>
                                </div>
                            </div>
                            <OpenPositionDescription>
                                {data?.content}
                            </OpenPositionDescription>
                        </>
                    )
                ]}
                color="#808080"
                background="#ffffff"
            >
            </DrvContent>
            <div>
                <DrvContent
                    type={['text']}
                    content={[
                        (
                            <div id="forma">
                                <DrvApplyForm jobTitle={data?.title}/>
                            </div>
                        )
                    ]}
                >
                </DrvContent>
            </div>

        </>
    )
}

export default CareersOpenPosition

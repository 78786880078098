import styled from "styled-components";

export const Container = styled.div`
    font-family: 'Montserrat', sans-serif;
    border: solid 1px #c1c1c1;
    box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 32%);
    margin: 0 auto;
`;

export const ApplyInput = styled.input`
    width: 100%;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 16px 9px;
    border: 1px solid #e6e6e6;
    background-color: #fff;
    outline: none;
    height: auto;
    color: #a6a6a6;
    border-radius: 0;
    box-sizing: border-box;
    margin-bottom: 20px;
    &:focus{
        outline: 2px solid #0e6faa !important;
    }
    &.error{
        outline: 1px solid #f00;
    }
`;

export const UploadFile = styled.div`
    align-items: center;
`;

export const UploadFileText = styled.div`
    color: #a6a6a6;
    font-size: 14px;

`

export const SubmitButton = styled.button`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: #fff;
    white-space: nowrap;
    border:2px solid var(--primary-color);
    padding: 4px 35px;
    color: #545454;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    outline: none;
    min-width: 100px;
    line-height: 38px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s !important;
    border-radius: 50px;
    align-items: center;
    &:hover{
    background: var(--primary-color);
    border-color :#fff;
    color: #fff;
    }
`;

export const ErrorMessage = styled.div`
    background-color: #ffd9d9;
    color: #f00;
    margin: 30px 0 10px;
    padding: 18px 30px 18px 20px;
    font-size: 14px;
    border: none;
`;

export const ApplyTextArea = styled.textarea`
    width: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 0;
    outline: none;
    height: 112px;
    margin: 0 0 20px;
    color: #a6a6a6;
    padding: 10px 16px 9px;
    &:focus{
        outline: 2px solid #0e6faa !important;
    }
`;